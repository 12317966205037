<template>
    <StoryblokPageContainer v-if="story" :story="story" />
</template>

<script setup lang="ts">
import useStoryblokSeoData from '~/composables/useStoryblokSeoData';
import StoryblokPageContainer from '~/components/page-building/StoryblokPageContainer.vue';
import { useBreadcrumbStructuredData } from '~/composables/useStructuredData';

const { story, initBridge } = await useStoryblokStory();

if (story.value.content.breadcrumbs?.length > 0) {
    const structuredData = useBreadcrumbStructuredData(
        story.value.content.breadcrumbs,
    );

    useServerHead(() => ({
        script: structuredData,
    }));
}

// If the page contains a product_filter component and there are GET parameters,
// that means that we should exclude the page from Google's crawler so that it
// doesn't churn through duplicate pages due to industry parameters or Algolia filters:
const productFilters: any = story.value?.content?.blocks?.filter((block: any) => {
    return block.component == 'product_filter';
}) ?? [];
if (Object.keys(useRoute().query).length > 0 && productFilters?.length > 0) {
    story.value.content.robots_noindex = true;
}

useStoryblokSeoData(story.value);
onMounted(initBridge);
</script>
